/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Slider from "infinite-react-carousel";
import PropTypes from "prop-types";
import { CardMedia } from "@mui/material";

const BrandsCarousel = ({ data, itemsHeight, items }) => {
  const [slidesToShow, setSlidesToShow] = useState(null);
  const [showSlider, setShowSlider] = useState(false);

  const updateWidth = () => {
    setSlidesToShow(
      document.documentElement.clientWidth <= 425
        ? 1
        : document.documentElement.clientWidth >= 426 &&
          document.documentElement.clientWidth <= 672
        ? 1 * items
        : document.documentElement.clientWidth >= 673 &&
          document.documentElement.clientWidth <= 769
        ? 3 * items
        : document.documentElement.clientWidth >= 769 &&
          document.documentElement.clientWidth <= 1024
        ? 2 * items
        : 4 * items
    );
  };

  useEffect(() => {
    updateWidth();
    setTimeout(() => {
      setShowSlider(true);
    }, 100);
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    data &&
    showSlider &&
    Array.isArray(data) && (
      <Slider
        slidesToShow={slidesToShow}
        autoplay
        centerMode
        centerPadding={20}
        autoplaySpeed={2500}
      >
        {data.map(({ image }) => (
          <CardMedia
            key={`BrandsCarousel-${image}`}
            component="img"
            alt="logo"
            image={image}
            loading="lazy"
          />
        ))}
      </Slider>
    )
  );
};

BrandsCarousel.defaultProps = {
  isInHouse: false,
  itemsHeight: 140,
  items: 1,
};

BrandsCarousel.propTypes = {
  isInHouse: PropTypes.bool,
  itemsHeight: PropTypes.number,
  items: PropTypes.number,
};

export default BrandsCarousel;
