import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";

const Language = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChange = (event, newLanguage) => {
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const handleImageClick = (event, language) => {
    event.stopPropagation();
    handleChange(event, language);
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={selectedLanguage}
      onChange={handleChange}
    >
      <ToggleButton value="en" sx={{ color: "primary.main" }}>
        <Button onClick={(e) => handleImageClick(e, "en")} value="en">
          <img
            src="https://flagcdn.com/16x12/us.png"
            srcSet="https://flagcdn.com/32x24/us.png 2x, https://flagcdn.com/48x36/us.png 3x"
            width="16"
            height="12"
            alt="us"
          />
        </Button>
      </ToggleButton>
      <ToggleButton value="es" sx={{ color: "primary.main" }}>
        <Button onClick={(e) => handleImageClick(e, "es")} value="es">
          <img
            src="https://flagcdn.com/16x12/es.png"
            srcSet="https://flagcdn.com/32x24/es.png 2x, https://flagcdn.com/48x36/es.png 3x"
            width="16"
            height="12"
            alt="es"
          />
        </Button>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default Language;
