import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'

const ContainerIframe = ({ source, height }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)

  return (
    <>
      {loading && (
        <Box
          textAlign="center"
          height="100vh"
          sx={{ mt: 10 }}
          textTransform="capitalize"
        >
          <CircularProgress /> {t('loading')}...
        </Box>
      )}
      <iframe
        onLoad={() => setLoading(false)}
        width="100%"
        height={height}
        scrolling="yes"
        frameBorder={0}
        src={source}
        title="power brake exchange Netsuite"
      />
    </>
  )
}

ContainerIframe.propTypes = {
  source: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

export default ContainerIframe
