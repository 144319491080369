import React from "react";
import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";

const SectionBanner = ({ image, title, children }) => {
  const backgroundStyle = image
    ? {
        background: `url(${image}) no-repeat center center / cover`,
      }
    : { backgroundColor: "#B00020" };

  return (
    <Stack
      direction="column"
      textAlign="center"
      elevation={0}
      justifyContent="center"
      sx={{
        left: 0,
        width: "100%",
        height: "600px",
        ...backgroundStyle,
      }}
    >
      <Typography
        variant="h3"
        color="primary"
        fontWeight={800}
        textTransform="uppercase"
      >
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

SectionBanner.defaultProps = {
  image: null,
};

SectionBanner.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
};

export default SectionBanner;
