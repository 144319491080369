/* eslint-disable import/no-anonymous-default-export */
export default {
  whoWeAre: [
    {
      name: "company",
      link: "Aboutus",
    },
  ],

  support: [
    {
      name: "contact us",
      link: "Contactus",
    },
  ],
  products: [
    {
      name: "Product Portfolio",
      link: "Products",
    },
  ],
};
