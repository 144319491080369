import React from "react";
import { AppBar, Box } from "@mui/material";
import Language from "./language/Language";

const Topbar = () => {
  return (
    <AppBar position="static" color="primary">
      <Box p={2} marginLeft="auto">
        <Language />
      </Box>
    </AppBar>
  );
};

export default Topbar;
