import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Typography,
  Grid,
  Paper,
  Stack,
  CardMedia,
  Hidden,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import logo from "../../resources/Pbe-Logo.png";
import Topbar from "../Topbar";
import { ContentWrapper } from "../ContentWrapper";
import Menulist from "../Menulist/Menulist";
import PhoneIcon from "@mui/icons-material/Phone";
import { Link } from "react-scroll";
import "./Header.scss";

const number = " 305.635.1120";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
}));

const Header = () => {
  const [t] = useTranslation("global");

  const [links] = useState([
    { id: "Brands", name: t("header.Brands") },
    { id: "Products", name: t("header.Products") },
    { id: "Services", name: t("header.Services") },
    { id: "Aboutus", name: t("header.About us") },
    { id: "Contactus", name: t("header.Contact us") },
  ]);

  return (
    <Box>
      <AppBar position="static" color="secondary">
        <Topbar />
        <ContentWrapper extraPadding={false}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={7} sm={4.5}>
              <Stack direction="row" alignItems="center">
                <Hidden only={["sm", "md", "lg", "xl"]}>
                  <Menulist links={links} />
                </Hidden>
                <CardMedia
                  component="img"
                  alt="logo"
                  width={180}
                  image={logo}
                  loading="lazy"
                  sx={{ m: 1 }}
                />
              </Stack>
            </Grid>
            <Hidden only={["xs", "md"]}>
              <Grid item sm={1}>
                <Item />
              </Grid>
            </Hidden>
            <Grid
              item
              xs={2}
              sm={4.5}
              sx={{ textAlignLast: { xs: "left", md: "right" } }}
            >
              <Hidden only={["sm", "md", "lg", "xl"]}>
                <Typography variant="h5" color="primary">
                  <PhoneIcon sx={{ pr: 1 }} />
                </Typography>
              </Hidden>
              <Hidden only="xs">
                <Typography
                  variant="h5"
                  color="default"
                  textTransform="uppercase"
                >
                  {t("The brake parts specialists")}
                </Typography>
              </Hidden>
            </Grid>
          </Grid>
          <Hidden only="xs">
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={3}
              py={2}
            >
              <Grid item xs={8} sm>
                <Box style={{ display: "inline-block" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                  >
                    {links.map((link) => (
                      <Typography
                        variant="h5"
                        key={link.id}
                        component={Link}
                        to={link.id}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="header__link"
                      >
                        {link.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={1}>
                <Item />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{ textAlignLast: { sm: "left", md: "right" } }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h5" color="primary">
                    <PhoneIcon sx={{ pr: 1 }} />
                  </Typography>
                  <Typography variant="h5" color="default">
                    {number}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Hidden>
        </ContentWrapper>
      </AppBar>
    </Box>
  );
};

export default Header;
