import React from "react";
import { Grid, Box, Typography, List, ListItem } from "@mui/material";
import { ContentWrapper } from "../ContentWrapper";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
import "./Footer.scss";
import footerLinks from "./footerLinks";

export default function Footer() {
  const [t] = useTranslation("global");
  const { whoWeAre, support, products } = footerLinks;

  return (
    <Box className="footer">
      <ContentWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography className="footer__title" variant="h4">
              {t("banner.subtitle")}
            </Typography>

            <List sx={{ mt: 3, px: 0 }}>
              <ListItem className="footer__listItem">
                <Typography
                  fontWeight={400}
                  textTransform="capitalize"
                  variant="h6"
                  lineHeight={1}
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/5220+NW+72nd+Ave,+Miami,+FL+33166,+EE.+UU./@25.8216301,-80.316349,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9ba2dd009f23f:0x339cc48adb77bb8b!8m2!3d25.8216301!4d-80.316349!16s%2Fg%2F11cplgzkn8?entry=ttu"
                  className="footer__link"
                  style={{ display: "flex" }}
                >
                  5220 NW 72nd Ave, Suite #6 Miami, FL 33166
                </Typography>
              </ListItem>
              <ListItem className="footer__listItem">
                <Typography
                  fontWeight={400}
                  textTransform="capitalize"
                  variant="h6"
                  lineHeight={1}
                  className="footer__link"
                >
                  info@powerbrakexchange.com
                </Typography>
              </ListItem>
              <ListItem className="footer__listItem">
                <Typography
                  fontWeight={400}
                  textTransform="capitalize"
                  variant="h6"
                  lineHeight={1}
                  className="footer__link"
                >
                  P (1) 305.635.1120
                </Typography>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography className="footer__title" variant="h4">
              {t("footer.who we are")}
            </Typography>

            <List sx={{ mt: 3, px: 0 }}>
              {whoWeAre.map(({ name, link }) => (
                <ListItem key={link} className="footer__listItem">
                  <Typography
                    fontWeight={400}
                    textTransform="capitalize"
                    variant="h6"
                    component={Link}
                    to={link}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    lineHeight={1}
                    className="footer__link"
                  >
                    {t(name)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography className="footer__title" variant="h4">
              {t("footer.support")}
            </Typography>

            <List sx={{ mt: 3, px: 0 }}>
              {support.map(({ name, link }) => (
                <ListItem key={link} className="footer__listItem">
                  <Typography
                    fontWeight={400}
                    textTransform="capitalize"
                    variant="h6"
                    component={Link}
                    to={link}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    lineHeight={1}
                    className="footer__link"
                  >
                    {t(name)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography className="footer__title" variant="h4">
              {t("footer.products")}
            </Typography>

            <List sx={{ mt: 3, px: 0 }}>
              {products.map(({ name, link }) => (
                <ListItem key={link} className="footer__listItem">
                  <Typography
                    fontWeight={400}
                    textTransform="capitalize"
                    variant="h6"
                    component={Link}
                    to={link}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    lineHeight={1}
                    className="footer__link"
                  >
                    {t(name)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Box>
  );
}
