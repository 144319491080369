import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButtons: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          color: "#fff",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {},
      defaultProps: {
        variant: "outlined",
      },
    },
  },

  typography: {
    fontFamily: ["Pathway Gothic One"].join(", "),
    h1: {
      fontSize: "96px",
    },
    h2: {
      fontSize: "60px",
    },
    h3: {
      fontSize: "48px",
    },
    h4: {
      fontSize: "34px",
    },
    h5: {
      fontSize: "24px",
    },
    h6: {
      fontSize: "20px",
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: 1,
    },
    body1: {
      fontSize: "16px",
      lineHeight: 1,
    },
    body2: {
      fontSize: "14px",
      lineHeight: 1,
    },
    body3: {
      fontSize: "12px",
      lineHeight: 1,
    },
  },
  MuiCssBaseLine: {
    styleOverrides: `
      @font-face {
        font-family: 'interstate-condensed';
        font-style: normal;
        font-weight: 400;
      }
    `,
  },
  palette: {
    primary: {
      main: "#00a0dd",
    },
    secondary: {
      main: "#051C2C",
    },
    error: {
      main: "#AF1F25",
    },
    warning: {
      main: "#F5B300",
    },
    info: {
      main: "#F7F7F7",
    },
    default: {
      main: "#fff",
    },
    success: {
      main: "#4CAF50",
    },
  },
});

export default theme;
