import React from "react";
import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";

const CategoryItem = ({ id, name, image }) => {
  return (
    <Grid key={id} item xs={6} lg={3}>
      <Box className="link">
        <Paper elevation={1} sx={{ p: 2 }}>
          <CardMedia component="img" alt={name} image={image} loading="lazy" />
        </Paper>
        <Typography
          fontSize="16px"
          fontWeight={400}
          sx={{ my: 1, textAlign: "center" }}
        >
          {name || ""}
        </Typography>
      </Box>
    </Grid>
  );
};

export default CategoryItem;
