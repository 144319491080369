import "./App.scss";
import React, { useState } from "react";
import Banner from "./resources/we_know_brakes.png";

import Automann from "./resources/Brands/Brand-AUTOMANN.jpg";
import BBB from "./resources/Brands/Brand-BBB.jpg";
import Bendix from "./resources/Brands/Brand-Bendix.jpg";
import Centric from "./resources/Brands/Brand-Centric.jpg";
import DEXTER from "./resources/Brands/Brand-DEXTER-AXLE.jpg";
import FortPro from "./resources/Brands/Brand-FortPro.jpg";
import Haldex from "./resources/Brands/Brand-Haldex.jpg";
import Mico from "./resources/Brands/Brand-Mico.jpg";
import National from "./resources/Brands/Brand-National.jpg";
import SAP from "./resources/Brands/Brand-SAP.jpg";
import SKF from "./resources/Brands/Brand-SKF.jpg";
import ZF from "./resources/Brands/Brand-ZF.jpg";

import AirBrakeValves from "./resources/products/Air Brake Valves.png";
import AirBrakeCompressorKits from "./resources/products/Air Brake Compressor Kits.png";
import AirDryers from "./resources/products/Air Dryers.png";
import BrakeShoesKits from "./resources/products/Brake Shoes & Kits.png";
import BrakeLights from "./resources/products/Brake Lights.png";
import BrakeDrums from "./resources/products/Brake Drums.png";
import BrakePads from "./resources/products/Brake Pads (ADB).png";
import BrakeRotors from "./resources/products/Brake Rotors (ADB).png";
import CablesHoses from "./resources/products/Cables & Hoses.png";
import HardwareKits from "./resources/products/Hardware Kits.png";
import HydraulicBrakeCalipersPads from "./resources/products/Hydraulic Brake Calipers & Pads.png";
import WheelEnd from "./resources/products/Wheel End.png";

import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Header from "./component/Header/Header";
import { ContentWrapper } from "./component/ContentWrapper";
import CategoryItem from "./component/CategoryItem/CategoryItem";
import SectionBanner from "./component/SectionBanner/SectionBanner";
import BrandsCarousel from "./component/BrandsCarousel/BrandsCarousel";
import Footer from "./component/Footer/Footer";
import ContainerIframe from "./component/ContainerIframe";
import Bannercomponent from "./component/Banner/Banner";

function App() {
  const [t] = useTranslation("global");
  const [categories] = useState([
    { id: "1", name: t("product.product1"), image: AirBrakeValves },
    { id: "2", name: t("product.product2"), image: AirBrakeCompressorKits },
    { id: "3", name: t("product.product3"), image: AirDryers },
    { id: "4", name: t("product.product4"), image: BrakeShoesKits },
    { id: "5", name: t("product.product5"), image: BrakeLights },
    { id: "6", name: t("product.product6"), image: BrakeDrums },
    { id: "7", name: t("product.product7"), image: BrakePads },
    { id: "8", name: t("product.product8"), image: BrakeRotors },
    { id: "9", name: t("product.product9"), image: CablesHoses },
    { id: "10", name: t("product.product10"), image: HardwareKits },
    {
      id: "11",
      name: t("product.product11"),
      image: HydraulicBrakeCalipersPads,
    },
    { id: "12", name: t("product.product12"), image: WheelEnd },
  ]);

  const [data] = useState([
    { id: "1", name: "category1", image: Automann },
    { id: "2", name: "category1", image: BBB },
    { id: "3", name: "category1", image: Bendix },
    { id: "4", name: "category1", image: Centric },
    { id: "5", name: "category1", image: DEXTER },
    { id: "6", name: "category1", image: FortPro },
    { id: "7", name: "category1", image: Haldex },
    { id: "8", name: "category1", image: Mico },
    { id: "9", name: "category1", image: National },
    { id: "10", name: "category1", image: SAP },
    { id: "11", name: "category1", image: SKF },
    { id: "12", name: "category1", image: ZF },
  ]);

  return (
    <Box className="App">
      <Header />
      <Bannercomponent />

      <ContentWrapper>
        <Typography
          id="Brands"
          name="Brands"
          variant="h3"
          py={10}
          color="primary"
          textTransform="uppercase"
          align="center"
          fontWeight={800}
        >
          {t("brands.title")}
        </Typography>
        <BrandsCarousel key="brands2" items={1.5} data={data} />
      </ContentWrapper>

      <Box pb="160px" sx={{ background: "#C1C1C11F" }}>
        <ContentWrapper>
          <Box pt={4}>
            <Typography
              id="Products"
              name="Products"
              variant="h3"
              pt={4}
              color="primary"
              textTransform="uppercase"
              align="center"
              fontWeight={800}
            >
              {t("product.title")}
            </Typography>
            <Typography
              variant="h4"
              pb={4}
              color="default"
              textTransform="uppercase"
              align="center"
              fontWeight={800}
            >
              {t("product.subtitle")}
            </Typography>
            <Grid container columns={12} spacing={5}>
              {categories.map((cat) => (
                <CategoryItem key={cat.id} {...cat} />
              ))}
            </Grid>
          </Box>
        </ContentWrapper>
      </Box>

      <Box id="Services" name="Services">
        <SectionBanner title={t("services.title")} image={Banner}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            mt={5}
          >
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("product.product1")}
              </Typography>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item2")}
              </Typography>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item3")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item4")}
              </Typography>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item5")}
              </Typography>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item6")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item7")}
              </Typography>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item8")}
              </Typography>
              <Typography variant="h4" mb={1} align="center" color="white">
                • {t("services.item9")}
              </Typography>
            </Grid>
          </Grid>
        </SectionBanner>
      </Box>

      <Box my="100px" id="Aboutus" name="Aboutus">
        <ContentWrapper>
          <Typography
            variant="h3"
            pb={4}
            color="primary"
            textTransform="uppercase"
            align="center"
            fontWeight={800}
          >
            {t("header.About us")}
          </Typography>
          <Typography variant="h5" pb={4} align="justify">
            {t("about.text1")}
          </Typography>
          <Typography variant="h5" pb={4} align="justify">
            {t("about.text2")}
          </Typography>
          <Typography variant="h5" align="justify">
            {t("about.text3")}
          </Typography>
        </ContentWrapper>
      </Box>
      <Box pb="160px" id="Contactus" name="Contactus">
        <ContentWrapper>
          <Typography
            variant="h3"
            pb={4}
            color="primary"
            textTransform="uppercase"
            align="center"
            fontWeight={800}
          >
            {t("header.Contact us")}
          </Typography>
          <Typography variant="h5" align="justify">
            {t("contact.text")}
          </Typography>
        </ContentWrapper>
      </Box>
      <ContentWrapper>
        <ContainerIframe
          source="https://3817209.extforms.netsuite.com/app/site/crm/externalleadpage.nl/compid.3817209/.f?formid=61&h=AAFdikaITco_YN61kv_hvwGtFVNGV4szcFCTp-uVLY7FFdUD9iE&redirect_count=1&did_javascript_redirect=T"
          height="750"
        />
      </ContentWrapper>

      <Footer />
    </Box>
  );
}

export default App;
