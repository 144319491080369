import React from "react";
import imagebanner from "../../resources/2022-10-31-PBE-WebsitePhoto.png";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CardMedia,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

const Banner = () => {
  const [t] = useTranslation("global");
  const isSm = useMediaQuery("(max-width: 600px)");
  const isMd = useMediaQuery("(max-width: 960px)");
  const isLg = useMediaQuery("(max-width: 1366px)");
  const isXl = useMediaQuery("(max-width: 1920px)");

  const getParams = () => {
    if (isSm) return { height: "240", header: "h5", subheader: "h6" };
    if (isMd) return { height: "320", header: "h5", subheader: "h5" };
    if (isLg) return { height: "420", header: "h4", subheader: "h4" };
    if (isXl) return { height: "992", header: "h2", subheader: "h2" };
    return { height: "992", header: "h2", subheader: "h2" };
  };

  const params = getParams();

  return (
    <Box>
      <ImageListItem>
        <CardMedia
          component="img"
          alt="logo"
          width={1894}
          height={params.height}
          image={imagebanner}
          loading="lazy"
        />
        <ImageListItemBar
          title={
            <Box
              textAlign="left"
              sx={{
                whiteSpace: "pre-wrap",
                ml: { sm: 2, xl: 40 },
                width: { xs: "80%", sm: "50%" },
              }}
            >
              <Stack direction="column" mb={1}>
                <Typography
                  fontWeight={800}
                  variant={params.header}
                  textTransform="uppercase"
                  lineHeight={1.2}
                  mt={1}
                >
                  {t("banner.title")}
                </Typography>
                <Typography
                  fontWeight={800}
                  variant={params.header}
                  textTransform="uppercase"
                  lineHeight={1.2}
                  mt={1}
                >
                  {t("banner.subtitle")}
                </Typography>
              </Stack>

              <Button
                variant="contained"
                size="large"
                sx={{ my: { xs: 0, sm: 2 }, color: "white" }}
                component="a"
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/5220+NW+72nd+Ave,+Miami,+FL+33166,+EE.+UU./@25.8216301,-80.316349,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9ba2dd009f23f:0x339cc48adb77bb8b!8m2!3d25.8216301!4d-80.316349!16s%2Fg%2F11cplgzkn8?entry=ttu"
              >
                {t("banner.button")}
              </Button>
              <Stack direction="column" mb={1}>
                <Typography
                  fontWeight={400}
                  variant={params.subheader}
                  sx={{ mt: { xs: 0, sm: 1 } }}
                >
                  5220 NW 72nd Ave, Suite#6 Miami, FL 33166
                </Typography>
              </Stack>
            </Box>
          }
          sx={{
            height: "100%",
            background: {
              xs: "rgba(0, 0, 0, 0.2)",
              sm: "rgba(0, 0, 0, 0.1)",
              md: "transparent",
            },
          }}
        />
      </ImageListItem>
    </Box>
  );
};

export default Banner;
