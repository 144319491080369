import React from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import classNames from "classnames";

export const ContentWrapper = ({ children, extraPadding }) => {
  return (
    <Box
      className={classNames("container container__content", {
        "container--noPadding": !extraPadding,
      })}
    >
      <>{children}</>
    </Box>
  );
};

ContentWrapper.defaultProps = {
  extraPadding: true,
};

ContentWrapper.propTypes = {
  extraPadding: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
